import React from 'react';

export default class SpeakerDeck extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.setAttribute("class","speakerdeck-embed");
    s.setAttribute("data-id",this.props['data-id']);
    s.setAttribute("data-ratio", "1.7777");
    s.setAttribute("key", "sd-" + this.props['data-id']);
    s.src="//speakerdeck.com/assets/embed.js"
    s.setAttribute("async",true);
    this.instance.appendChild(s);
  }

  render() {
    return <div key="{{this.props['data-id']}}" ref={el => (this.instance = el)} />;
  }
}

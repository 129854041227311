import React from 'react'
import { Link } from 'gatsby'
import { FaLinkedin } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaDrupal } from 'react-icons/fa';

const SiteFooter = ({ siteTitle }) => (
  <div
    className={"site-footer"}
    style={{
      background: '#1b3579',
      marginBottom: '0rem',
    }}
  >
  <div
    style={{
      marginBottom: '0',
      borderTop: '12px solid #ffff00' 
    }}
  >
  </div>
  <div
    style={{
      marginBottom: '0',
      borderTop: '12px solid #e1190f' 
    }}
  >
  </div>
  <div
    style={{
      margin: '0 auto',
      maxWidth: 1024,
      padding: '1.1rem 0rem',
      fontSize: '18x',
      color: 'white',
    }}
  >
    <p>
      <a class="icon" href="https://www.linkedin.com/in/andrewlarcombe/"><FaLinkedin /></a>
      <a class="icon" href="https://www.twitter.com/andrewl"><FaTwitter /></a>
      <a class="icon" href="https://www.drupal.org/u/alarcombe"><FaDrupal /></a>
    </p>
    <p>
      A serverless website built with Drupal, Gatsby, AWS and Cloudflare. Site colours based on the classic <a href="https://s7169.pcdn.co/wp-content/uploads/2012/02/raleigh-pista-23.jpg">Raleigh Panasonic</a> pro cycling team.
    </p>
  </div>
  </div>
)

export default SiteFooter;

import { graphql } from "gatsby"
import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import SpeakerDeck from '../components/speakerdeck'
import Img from "gatsby-image"
import styles from './article.module.css'
import ReactHtmlParser from 'react-html-parser';
import Iframe from 'react-iframe';
import Gist from 'react-gist';
import {DiscussionEmbed} from 'disqus-react';
import { Link } from "gatsby"

const ArticleTemplate = ({ data }) => {

  const article = data.allNodeArticle.edges[0].node;
  let image = (<React.Fragment/>);
  if (article.relationships.field_image) {
    console.log(article.relationships.field_image);
    image = (
      <Img
        className={styles.articlePageImage}
        fluid={
          article.relationships.field_image.localFile
            .childImageSharp.fluid
        }
      />
    );
  }

  let article_body_elements = new ReactHtmlParser(article.body.processed, {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let uuid = node.attribs["data-entity-uuid"];
        let i = 0;
        for (i = 0; i < data.allFileFile.edges.length; i++) {
          if (data.allFileFile.edges[i].node.uuid === uuid &&
            data.allFileFile.edges[i].node.localFile) {
            let style = {
              maxWidth: data.allFileFile.edges[i].node.localFile.childImageSharp.fluid.presentationWidth,
              margin: "0 auto"
            }
            return <Img style={style} fluid={data.allFileFile.edges[i].node.localFile.childImageSharp.fluid}/>
          }
        }
      }
      else if (node.type === 'tag' && node.name === 'iframe') {
        let url = node.attribs.src;
        if (url.search('http') === -1) {
          if (url.search('//') === -1) {
            url = "//" + url;
          }
          url = "https:" + url;
        }
        let id = url.split("/").pop();
        return <Iframe url={url}
                width={node.attribs.width}
                height={node.attribs.height}
                id={id}
                display="initial"
                position="relative"
                allowFullScreen/>;
      }
      else if (node.type === 'script') {
        if ('src' in node.attribs && 
          'data-id' in node.attribs) {
          if (node.attribs['src'].search('speakerdeck') > -1) {
            return <SpeakerDeck
              data-id={node.attribs['data-id']} />;
          }
        }
        else if ('src' in node.attribs) {
          if (node.attribs['src'].search('gist.github') > -1) {
            var id = node.attribs['src'].split('/').pop().split('.')[0];
            return <Gist id={id} />;
          }
        }

      }

      return undefined;
    }
  });

  let disqusProps = {
    shortname: "andrewl",
    config: {
      identifier: data.nid,
      title     : article.title
    },
  };

  let article_component = (
    <Layout>
      <SEO title={article.title} description={article.body.summary} image={article.relationships.field_image && article.relationships.field_image.localFile.childImageSharp.fluid.base64} url={article.path.alias} isArticle="true" />
      <div>
        <h1>{article.title}</h1>
        {image}
        {article_body_elements}
        <p><em>I'm Andrew - a Tech Architect and Agile Delivery Consultant. Find out more about the services I offer <Link to="/about">here</Link></em></p>
      </div>
      <DiscussionEmbed {...disqusProps}/>
    </Layout>
  );

  return article_component;
}

export default ArticleTemplate

export const query = graphql`
query($nid: Int!) {
  allNodeArticle(filter: {nid:{eq: $nid}}) {
    edges {
      node {
        nid
        title
        created
        path {
          alias
        }
        body {
          summary
          processed
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  allFileFile{
    edges {
      node {
        uuid
        localFile {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
}
`

import React from 'react'
import { Link } from 'gatsby'

const SiteHeader = ({ siteTitle }) => (
  <div
    style={{
      marginBottom: '0',
      background: 'rgb(27, 53, 121)',
      borderBottom: 'none',
      paddingTop: '1em',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 1024,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: '#fffffa',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  <div
    style={{
      marginBottom: '0',
      borderBottom: '12px solid #ffff00' 
    }}
  >
  </div>
  <div
    style={{
      marginBottom: '0',
      borderBottom: '12px solid #e1190f' 
    }}
  >
  </div>
  </div>
)

export default SiteHeader

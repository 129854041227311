import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ title, description, image, url, isArticle }) => {

  return (
    <React.Fragment>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="canonical" href={url} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isArticle ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@andrewl" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    </React.Fragment>
  );

}

SEO.propTypes = {
  isArticle: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.any,
  url: PropTypes.string
};

SEO.defaultProps = {
  isArticle: false,
  description: "",
  image: null
};

export default SEO;
